import Form from '../components/inputs/Form';
import Input from '../components/inputs/Input';
import { useNavigate } from 'react-router';

import Select from '../components/inputs/Select';
import { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';

import './CreateActivityPage.scss';


export default function CreateActivityPage() {

   const navigate = useNavigate();

   const onSubmit = (e, data) => {

      if ('data' in data) {
         navigate('/activities/' + document.getElementById('create-ad-url').value + '/edit');
      }

   }

   const [cities, setCities] = useState([]);
   const [activityOptions, setActivityOptions] = useState([]);

   const checkURL = (e) => {

      const formData = new FormData();
      formData.append('url', e.target.value)

      if (e.target.value.length < 8) {
         return;
      }

      const requestOptions = {
         method: 'POST',
         body: formData
      }

      fetch('/api/v1/activities/urls', requestOptions)
         .then(response => response.json())
         .then((data) => {


            if ('error' in data && !e.target.classList.contains('error')) {
               e.target.parentElement.classList.remove('valid');
               e.target.classList.add('error');
               e.target.classList.remove('valid');
               const errorMessage = document.createElement('p');
               errorMessage.classList.add('error-message');
               errorMessage.textContent = 'Deze link bestaat al. Kies een andere.';
               e.target.parentElement.insertAdjacentElement("afterend", errorMessage);
            }
      })

   }


   const request = {
      options: {
          endpoint: "/api/v1/options",
          method: "GET",
      },
      cities: {
         endpoint: "/api/v1/cities",
         method: "GET",
      }
   }

   const [data, loading, error] = useFetch(request);

   useEffect(() => {

      if (data.options && data.cities) {
            
         const newActivityOptions = [];
         for (const option of data.options.data) {
            newActivityOptions.push({value: option.id, name: option.pretty_name, category: option.category});
         }
         setActivityOptions(newActivityOptions);

         const newCities = [];
         for (const city of data.cities.data) {
            newCities.push({
               value: city.id,
               name: city.name,
               category: city.province
            });
         }
         setCities(newCities);

      }

   }, [loading]);

   useEffect(() => {
      document.title = "Nieuwe Activiteit Aanmaken - You Be Well";
   }, []);  


   return (
      <main id="create-activity">

         <div className="container">

            <h2>Profiel aanmaken<span className="stress">.</span></h2>
            <p>Begin hier met het aanmaken van je profiel.</p>

            <div className="form-container">

               <Form
                  action="/api/v1/activities"
                  submitBtnText="Aanmaken"
                  submitCallback={onSubmit}
                  autoComplete="off"
                  noHoneyPot
                  >

                  <h3>De basis<span className="stress">.</span></h3>

                  <Input
                     type="text"
                     name="location_name"
                     example="Bijv. You Be Well"
                     placeholder="Naam van jouw bedrijf"
                     minLength="2"
                     maxLength="40"
                     icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M20.067 3.139c-.703.988-1.258 1.978-1.696 2.958l.743.272-.346.941-.783-.289c-.204.532-.374 1.062-.515 1.59l.873.177-.2.983-.894-.182c-.121.639-.196 1.275-.223 1.91h.986v1.002h-.994c.02.638.085 1.277.2 1.912l.908-.183.202.982-.896.181c.138.533.312 1.067.518 1.6l.818-.302.346.94-.776.286c.452.987 1.022 1.976 1.712 2.96 2.419-2.196 3.95-5.354 3.95-8.877 0-3.513-1.523-6.666-3.933-8.861m-3.61 15.47l-1.105.406-.347-.94 1.068-.393c-.239-.628-.443-1.259-.603-1.891l-1.073.218-.199-.983 1.066-.216c-.14-.768-.219-1.537-.241-2.309h-1.019v-1.002h1.026c.031-.767.119-1.536.265-2.306l-1.08-.219.2-.982 1.096.222c.16-.627.358-1.255.597-1.883l-1.103-.404.347-.941 1.138.419c.511-1.169 1.13-2.333 1.965-3.508-1.866-1.195-4.077-1.897-6.455-1.897-2.383 0-4.599.705-6.467 1.904.841 1.173 1.467 2.335 1.977 3.501l1.138-.419.345.941-1.101.404c.238.628.437 1.256.597 1.883l1.096-.222.199.982-1.08.219c.147.77.236 1.539.266 2.306h1.025v1.002h-1.019c-.021.772-.101 1.541-.241 2.309l1.066.216-.199.983-1.074-.218c-.158.632-.362 1.263-.601 1.891l1.066.393-.345.94-1.104-.406c-.52 1.168-1.145 2.328-2.021 3.481 1.87 1.203 4.089 1.91 6.477 1.91 2.384 0 4.603-.706 6.471-1.907-.867-1.154-1.497-2.315-2.014-3.484m-12.507 2.269c-2.421-2.196-3.951-5.354-3.951-8.878 0-3.518 1.526-6.674 3.942-8.87.698.971 1.244 1.976 1.688 2.967l-.744.272.347.941.784-.289c.203.532.374 1.062.513 1.59l-.873.177.2.983.895-.182c.121.639.196 1.275.224 1.91h-.988v1.002h.995c-.021.638-.086 1.277-.201 1.912l-.908-.183-.2.982.894.181c-.137.533-.312 1.067-.516 1.6l-.819-.302-.347.94.777.286c-.45.984-1.003 1.964-1.712 2.961"/></svg>}
                     explicitValidation
                     required
                  />

                  <Input
                     id="create-ad-url"
                     type="text"
                     name="url"
                     example="Bijv. studio-relax (jouw profiellink wordt dan www.youbewell.nl/studio-relax)"
                     placeholder="Profiellink"
                     minLength="8"
                     maxLength="20"
                     onBlur={checkURL}
                     icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/></svg>}
                     explicitValidation
                     urlOnly
                     required
                  />

                  <Select 
                     id="create-ad-options"
                     name="options"
                     example="Bijv. coaching"
                     placeholder="Aangeboden activiteiten"
                     icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M23.769 14.359c-1.097 5.495-5.952 9.641-11.768 9.641-6.623 0-12-5.377-12-12s5.377-12 12-12c2.68 0 5.656 1.047 7.598 2.774-2.604-.958-5.138-.87-6.553-.626-3.951.757-6.731 3.32-7.972 6.232-2.447 5.743 1.359 10.347 5.599 10.343 2.746 0 5.152-1.853 6.583-4.202 1.099-1.802 2.308-2.388 3.187-2.357 1.259.044 2.089.566 3.326 2.195zm.231-2.541c-.981-.94-2.085-1.612-3.535-1.662-1.903-.065-3.726 1.37-4.916 3.323-1.007 1.652-2.444 2.795-3.941 3.136-3.359.765-6.683-2.785-4.694-7.451 3.461-8.121 13.861-4.826 14.826-3.618.798.999 2.219 3.515 2.26 6.272z"/></svg>}
                     options={activityOptions}
                     className="z-index-select-9"
                     max={5}
                     explicitValidation
                     required
                     multi
                     />

               </Form>

            </div>

         </div>

      </main>
   );

}